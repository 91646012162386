<template>
  <div class="room-page">
    <div class="data-block theme-bg">
      <div class="data-box">
        <div class="data-item">
          <div class="word">{{ $i18n.t("room_available") }}</div>
          <div class="num font-m">
            {{ userInfo.availableETH | substr6 }}
          </div>
        </div>
        <div class="data-item">
          <div class="word">{{ $i18n.t("room_freeze") }}</div>
          <div class="num font-m">
            {{ userInfo.calcFrozenEth }}
          </div>
        </div>
        <div class="data-item">
          <div class="word">{{ $i18n.t("room_earnings") }}</div>
          <div class="num font-m">
            {{ userInfo.todayReturnsETH | substr6 }}
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <template slot="pulling">
        {{ $i18n.t("rd_pull_to_refresh") }}
      </template>
      <template slot="loosing">
        {{ $i18n.t("rd_pull_to_refresh") }}
      </template>
      <template slot="loading">
        {{ $i18n.t("rd_loading") }}
      </template>
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="finishedText"
          @load="loadList"
        >
        <template slot="finished">
          {{ $i18n.t("rd_no_more") }}
        </template>
        <template slot="loading">
          {{ $i18n.t("rd_loading") }}
        </template>
          <div class="room-list">
            <div
              class="room-item"
              v-for="(item, index) in roomList"
              :key="index"
              @click="toDetail(item.id)"
            >
              <div class="level">
                <img :src="item.iconUrl" alt="" />
              </div>
              <div class="pic-box">
                <img :src="item.imageUrl" />
              </div>
              <div class="info-box">
                <div class="name font-m text-e">{{ item.title }}</div>
                <div class="price text-e">{{ item.minLimit }}-{{ item.maxLimit }} ETH</div>
                <div class="link">
                  <a href="###" class="f24 color-999">Click to enter</a>
                  <img src="../assets/images/icon-arraw-right2.svg" alt="" />
                </div>
              </div>
            </div>
            <!-- <van-empty :description="description" /> -->
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
    <tab-bar :currentIndex="currentTab" @changeTab="changeTab" />
  </div>
</template>

<script>
import TabBar from "@/components/TabBar.vue";
function getFullNum(num){
    //处理非数字
    if(isNaN(num)){return num};
    //处理不需要转换的数字
    var str = ''+num;
    if(!/e/i.test(str)){return num;};
    return (num).toFixed(18).replace(/\.?0+$/, "");
}
export default {
  components: {
    TabBar,
  },
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: true,
      userInfo: {
        calcFrozenEth: 0,
        availableETH: 0,
        todayReturnsETH: 0,
      },
      roomList: [],
      currentTab: 1,
    };
  },
  computed: {
    description() {
      return this.$i18n.t("in_no_data")
    },
    finishedText() {
      return this.$i18n.t("record_nomore");
    },
  },
  filters: {
    fixed_6: function (value) {
      if (!value && value !== 0) return 0;
      return value.toFixed(6);
    },
    substr6: function (val) {
      if (!val && val !== 0) return 0;
      val = typeof val === 'number' ? `${getFullNum(val)}` : val
      const [f, n] = val.split('.')
      return val == '0' ? 0 : `${f}${n ? `.${n.substr(0, 6)}` : ''}`
    }
  },
  created() {
    this.getRoomList();
  },
  methods: {
    substr61(val) {
      val = typeof val === 'number' ? `${getFullNum(val)}` : val
      const [f, n] = val.split('.')
      return val == '0' ? 0 : `${f}${n ? `.${n.substr(0, 6)}` : ''}`
    },
    onRefresh() {
      this.getRoomList();
    },
    loadList() {},
    getRoomList() {
      let that = this;
      this.$http.getEthPrice().then((res) => {
        if (res.code === 0) {
          const price = res.data.ethPrice.price;
          this.$http.getRoomList().then((_res) => {
            if (_res.code === 0) {
              that.roomList = _res.data.list.map((v) => {
                return {
                  ...v,
                  minLimit: that.substr61(v.minLimit / price),
                  maxLimit: that.substr61(v.maxLimit / price),
                };
              });
            }
            this.finished = true;
            this.loading = false;
            this.refreshing = false;
          });
          this.$http.getUserInfo().then((res) => {
            console.log(res);
            if (res.code === 0) {
              that.userInfo = res.data;
              that.userInfo.calcFrozenEth = that.substr61(
                res.data.frozen / price +
                res.data.unsettledETH
              );
            }
          });
        }
      });
    },
    changeTab(index) {
      this.currentTab = index;
    },
    toDetail(id) {
      // this.$router.push("/room-detail");
      this.$router.push({path: "/room-detail", query: {id: id}});
    },
  },
};
</script>

<style lang="less">
.room-page {
  .data-block {
    padding: 1.36rem 0.48rem 0.6rem 0.48rem;
    .data-box {
      display: flex;
      width: 100%;
      background: rgba(255, 255, 255, 0.5);
      border-radius: 0.32rem;
      border: 1px solid #fff;
      .data-item {
        padding: 0.3rem 0;
        width: 33.3%;
        text-align: center;
        display: flex;
        flex-direction: column;
        .word {
          color: #666;
          flex: 1;
          font-size: 0.28rem;
          margin-bottom: 0.2rem;
        }
        .num {
          color: #333;
          line-height: 0.32rem;
          font-size: 0.28rem;
          font-weight: bold;
        }
      }
    }
  }
  .content {
    background: #fff;
    border-top-left-radius: 0.32rem;
    border-top-right-radius: 0.32rem;
    transform: translateY(-0.2rem);
    padding: 0.6rem 0.48rem;
    padding-bottom: 1.4rem;
    .room-list {
      // display: flex;
      // flex-wrap: wrap;
      // justify-content: space-between;
      .room-item {
        border: 1px solid #f5f5f5;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
        // width: calc(50% - 8px);
        height: 2.48rem;
        margin-bottom: 0.3rem;
        border-radius: 0.6rem;
        position: relative;
        overflow: hidden;
        display: flex;
        .level {
          position: absolute;
          top: 0;
          right: 0;
          width: 1.4rem;
          height: 0.7rem;
          border-bottom-left-radius: 0.48rem;
          background: linear-gradient(90deg, #a5edff 0%, #93fff0 49%, #d3ffe1 100%);
          img {
            width: 0.82rem;
            height: 0.66rem;
          }
        }
        .pic-box {
          width: 2.48rem;
          height: 2.48rem;
          display: flex;
          overflow: hidden;
          margin: 0 auto;
          img {
            width: 2.48rem;
            height: 2.48rem;
          }
        }
        .info-box {
          width: calc(100% - 2.48rem);
          padding-left: 0.3rem;
          text-align: left;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .name {
            color: #333;
            font-size: 0.32rem;
            line-height: 0.4rem;
            margin-bottom: 0.1rem;
          }
          .price {
            color: #999;
            font-size: 0.28rem;
            margin-bottom: 0.4rem;
          }
          .link {
            img {
              width: 0.24rem;
            }
          }
        }
      }
    }
  }
}
</style>
